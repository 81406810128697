import { useEffect, lazy, Suspense } from 'react';
import i18n from "./i18n";
import { useLocation } from 'react-router-dom';
import { hostname, store } from './configs/config';

import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';
import  { jdsports, size, hipstore, blacks, fishingrepublic, gooutdoors, millets, naylors, ultimateoutdoors, footpatrol }  from './configs/constants';

const Hipstore = lazy(() => import("./hipstore/Hipstore"));
const NoPage = lazy(() => import('./nopage'));
const Jdsports = lazy(() => import('./jdsports/jdsports'));
const Size = lazy(() => import('./size/Size'));
const Blacks = lazy(() => import('./blacks/blacks'));
const Fishingrepublic = lazy(() => import('./fishingrepublic/fishingrepublic'));
const Gooutdoors = lazy(() => import('./gooutdoors/gooutdoors'));
const Millets = lazy(() => import('./millets/millets'));
const Naylors = lazy(() => import('./naylors/naylors'));
const Ultimateoutdoors = lazy(() => import('./ultimateoutdoors/ultimateoutdoors'));
const Footpatrol = lazy(() => import('./footpatrol/footpatrol'));
i18n.changeLanguage(store['locale']);
if(store['GAcode'])
    ReactGA.initialize(store['GAcode']);
export default function Fascia() {
    const location = useLocation();
    const {t} = useTranslation();
    useEffect(() => {
        
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: t("JD Sports Returns")});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }
        link.href = 'images/'+store['locale']+'/favicon.ico';
        document.title = t("JD Sports Returns");
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [t("Returns")]);
      console.log(hostname)
    if(jdsports.includes(hostname) || hostname === "dk6lm2ma5qlvg.cloudfront.net") {
        return (<Suspense><Jdsports /></Suspense>);
    }
    if(hipstore.includes(hostname)) {
        return (<Suspense><Hipstore /></Suspense>);
    }
    else if(size.includes(hostname)) {
        return <Size />;
    }
    else if(hipstore.includes(hostname)) {
        return (<Suspense><Jdsports /></Suspense>);
    }
    else if(blacks.includes(hostname)) {
        return <Blacks />;
    }
    else if(fishingrepublic.includes(hostname)) {
        return <Fishingrepublic />;
    }
    else if(gooutdoors.includes(hostname)) {
        return <Gooutdoors />;
    }
    else if(millets.includes(hostname)) {
        return <Millets />;
    }
    else if(ultimateoutdoors.includes(hostname)) {
        return <Ultimateoutdoors />;
    }
    else if(naylors.includes(hostname)) {
        return <Naylors />;
    }
    else if(footpatrol.includes(hostname)) {
        return <Footpatrol />;
    }
    else {
        return <NoPage />;
    }
}