//domains
export const jdsports = [//uat urls
        "returns-jdsportsat.rome.support", "returns-jdsportsbe.rome.support", "returns-jdsportsde.rome.support", "returns-jdsportsdk.rome.support","returns-jdsportsuk.rome.support","returns-jdsportses.rome.support", "returns-jdsportsfi.rome.support", "returns-jdsportsfr.rome.support", "returns-jdsportsie.rome.support", "returns-jdsportsit.rome.support", "returns-jdsportsnl.rome.support", "returns-jdsportspt.rome.support", "returns-jdsportsse.rome.support", "returns-jdsportsglobal.rome.support", "ruecksendung.jdsports.at",//Prod urls
        "retourneren.jdsports.be", "returnering.jdsports.dk", "palautukset.jdsports.fi", "retour.jdsports.fr", "ruecksendung.jdsports.de", "resi.jdsports.it", "retourneren.jdsports.nl", "devolucoes.jdsports.pt", "devoluciones.jdsports.es", "retur.jdsports.se", "returns.jdsports.co.uk", "returns.global.jdsports.com", "returns.jdsports.ie",
    ];
export const hipstore = [//uat urls
    "returns-hipstoreuk.rome.support",//Prod urls
    "returns.thehipstore.co.uk"
];
export const size = [//uat urls
    "returns-sizeofficialbe.rome.support", "returns-sizeofficialdk.rome.support", "returns-sizeofficialfi.rome.support", "returns-sizeofficialfr.rome.support", "returns-sizeofficialde.rome.support", "returns-sizeofficialit.rome.support", "returns-sizeofficialnl.rome.support", "returns-sizeofficiales.rome.support", "returns-sizeofficialse.rome.support", "returns-sizeuk.rome.support", "returns-sizeofficialie.rome.support",
    //Prod urls
    "retourneren.sizeofficial.be", "returnering.sizeofficial.dk", "palautukset.sizeofficial.fi", "retour.sizeofficial.fr", "ruecksendung.sizeofficial.de", "resi.sizeofficial.it", "retourneren.sizeofficial.nl", "devoluciones.sizeofficial.es", "retur.sizeofficial.se", "returns.size.co.uk", "returns.sizeofficial.ie"
];
export const blacks = ["returns-blacks.rome.support", "returns.blacks.co.uk"];
export const fishingrepublic = ["returns-fishingrepublic.rome.support", "returns.fishingrepublic.co.uk"];
export const gooutdoors = ["returns-gooutdoors.rome.support", "returns.gooutdoors.co.uk"];
export const millets = ["returns-millets.rome.support", "returns.millets.co.uk"];
export const ultimateoutdoors = ["returns-ultimateoutdoors.rome.support", "returns.ultimateoutdoors.com"];
export const naylors = ["returns-naylors.rome.support", "returns.naylors.com"];
export const footpatrol = ["returns-footpatroldk.rome.support", "returns-footpatrolfi.rome.support", "returns-footpatrolfr.rome.support", "returns-footpatrolde.rome.support", "returns-footpatrolit.rome.support", "returns-footpatrolnl.rome.support", "returns-footpatrolse.rome.support", "returns-footpatroluk.rome.support", "returns-footpatrolnl.rome.support", "returns-footpatrolie.rome.support", 
//Prod urls
"returnering.footpatrol.dk", "palautukset.footpatrol.fi", "retour.footpatrol.fr", "ruecksendung.footpatrol.de", "resi.footpatrol.it", "retourneren.footpatrol.nl", "retur.footpatrol.se", "returns.footpatrol.com", "returns.footpatrol.ie"
];
//themes
export const jdsportsTheme = ["--logo-bg-color:#000000 !important;--logo-max-width: 270px;--bg_black-padding: 40px 20px;--bg_black-text-align: left;--bg_black-border-bottom: none;--footercls-bg-color:#fff;--footerseperater-bg-color:#2e2e2e;--footercls-color: none;--submit_btn-hover-bg-color:#37ad90;--submit_btn-bg-color:#43d5b0;--main_div-bg-color: #fff;; --body-background-color: #fff;--font-family:Gotham SSm A, Gotham SSm B, Arial, sans-serif"];
export const hipstoreTheme = ["--logo-bg-color:#fff !important;--logo-max-width: 100px;--bg-class-border-bottom: 1px solid #333;--bg_black-padding: 20px;--bg_black-text-align: center;--bg_black-border-bottom:1px solid #d5d5d5;--footercls-bg-color:#333;--footercls-color: #fff;;--submit_btn-bg-color:#333;--submit_btn-hover-bg-color:#333;--main_div-bg-color: #fff; --body-background-color: #333;--font-family:'acumin-pro',Helvetica,Arial,sans-serif;--logo-margin-left:0px"];
export const sizeTheme = ["--logo-bg-color:#000 !important;--logo-max-width: 130px;--bg-class-border-bottom: 1px solid #000;--bg_black-padding: 20px;--bg_black-text-align: left;--bg_black-border-bottom:1px solid #d5d5d5;--footercls-bg-color:#000;--footercls-color: #fff;;--submit_btn-bg-color:#000;--submit_btn-hover-bg-color:#333;--main_div-bg-color: #fff; --body-background-color: #000; --asstric-bg-color: #43d5b0;--font-family: Verdana, Helvetica, Arial, sans-serif"];
export const blacksTheme = ["--theme-primary-color: #FF6633;--logo-bg-color:#333 !important;--logo-max-width: 170px;"];
export const fishingrepublicTheme = ["--theme-primary-color: #465538;--logo-bg-color:#fff !important;--logo-max-width: 270px;--bg-class-border-bottom: 1px solid #333"];
export const gooutdoorsTheme = ["--theme-primary-color: #92A396 !important;--logo-bg-color:#545659 !important;--logo-max-width: 270px"];
export const milletsTheme = ["--theme-primary-color: #82b925;--logo-bg-color:#616161 !important;--logo-max-width: 170px;"];
export const ultimateoutdoorsTheme = ["--theme-primary-color: #00665e !important;--logo-bg-color: #00665e !important;--logo-max-width: 170px;"];
export const naylorsTheme = ["--theme-primary-color: #fcd006;--logo-bg-color:#fff !important;--logo-max-width: 270px;--bg-class-border-bottom: 1px solid #999"];
export const footpatrolTheme = ["--theme-primary-color: #465538; --submit_btn-bg-color: #43d5b0;--logo-bg-color:#fff !important;--logo-max-width: 270px;--bg-class-border-bottom: 1px solid #333"];