import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./nopage";
import Fascia from "./Fascia";
export default function Router() {
    return (
        <BrowserRouter>
          <Routes>
            <Route path="/">
            <Route index element={<Fascia />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      );
}